import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

const WhyHomeSpotter = ({ data, location }) => {
    return (
        <Layout
            title="About Us | HomeSpotter"
            description="HomeSpotter's story and what sets us apart"
        >
            <OurCompanyNavigationRow location={location} />
            <div className="ourcompany whyhomespotter col-12">
                <h1>We are real estate’s relationship engine</h1>
                <GatsbyImage
                    image={data.file.childImageSharp.gatsbyImageData}
                    className="picture"
                />
                <div className="whyhomespotter content">
                    <div>
                        <div className="section-header">About HomeSpotter</div>
                        <p>
                            HomeSpotter helps brokerages, agents, and MLSs build
                            relationships amongst one another and with
                            clients—we are real estate's relationship engine.
                            Our digital suite of tools (Connect, Spacio, and
                            Boost) allow agents to collaborate with ease, work
                            on the go, and increase their productivity.
                            Brokerages and MLSs are better equipped to support
                            and retain agents and help grow their businesses.
                        </p>
                        <p>
                            We are driven to build scalable solutions that make
                            finding/selling homes a delightful experience for
                            everyone.
                        </p>
                    </div>
                    <div>
                        <div className="section-header">Our Story</div>
                        <p>
                            HomeSpotter was founded by Aaron Kardell in 2009,
                            when he saw an opportunity to create a solution that
                            allowed homebuyers to search for homes, which would
                            pull in real-time data from MLSs for brokerages and
                            agents. Ten years later, HomeSpotter has grown to
                            provide three digital solutions (Boost, Spacio, and
                            Connect) licensed by over 450,000 agents. In 2021,
                            we joined forces with Lone Wolf Technologies, the
                            real estate tech leader, bringing our solutions
                            together for agents, brokers, MLSs, and
                            associations.
                        </p>

                        <p>
                            We are a Minnesota-based company and take pride in
                            our heritage as we continue to expand our reach
                            across North America. We believe in empowering
                            employees to be resourceful and having the freedom
                            to create the best products. We invest in our
                            community, and have taken the{' '}
                            <a
                                target="_none"
                                alt="MN tech diversity"
                                name="MN tech diversity"
                                className="diversity"
                                href="https://mntechdiversity.com/"
                            >
                                Minnesota Technology Diversity Pledge
                            </a>{' '}
                            to create an inclusive workplace.
                        </p>
                    </div>
                    <div>
                        <div className="section-header">What sets us apart</div>
                        <strong>Relationships</strong>
                        <p>
                            Our solutions are developed with relationships in
                            mind. We start by asking: How can we empower agents
                            and clients to communicate easily and improve
                            collaboration? How can we help brokerages better
                            attract, support, and retain agents? How can we
                            empower MLSs to better support and add even more
                            value for their members?
                        </p>

                        <p>
                            These questions guide us as we develop and
                            continuously improve our solutions.
                        </p>

                        <strong>Integrity</strong>
                        <p>
                            We earn our client's trust by delivering when we say
                            we will and collaborating with them to find
                            solutions for their challenges.
                        </p>

                        <strong>Integrations</strong>
                        <p>
                            We take pride in building symbiotic relationships
                            with over 100+ vendors that allow us to integrate
                            important data with our solutions. We protect all
                            data that is shared with us, but we also make it
                            easily accessible to parties that have permission to
                            view and modify it.
                        </p>

                        <strong>Transparency</strong>
                        <p>
                            Our solutions are driven by data, so you'll always
                            see measurable results from each of our solutions,
                            and can share them with clients. We charge
                            predictable prices for our products, so you're
                            always in-the-know.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const WhyHomespotterRender = (props) => (
    <StaticQuery
        query={graphql`
            {
                file(relativePath: { eq: "why-homespotter-hero.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 940
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={(data) => <WhyHomeSpotter data={data} {...props} />}
    />
)

export default WhyHomespotterRender
